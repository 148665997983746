import { useEffect, useState } from "react"
import "./style.css"
import axiosInstance from "../axiosInstance"
import DefaultUser from "../static/photos/user.jpg"

const Testimonials = () => {

    const [data, setData] = useState([])
    const [activeIndex, setActiveIndex] = useState(1)

    useEffect(() => {
        getData()
        //eslint-disable-next-line
    }, [])

    const getData = () => {
        axiosInstance("feedback/", { method: "GET" })
            .then(res => {
                setData(res?.data?.results)
            }).catch(err => {
                getData()
                console.log('err: ', err);
            })
    }

    return (
        <>
            <section>
                <div className="customer-feedback">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-sm-offset-2 col-sm-8">
                                <div>
                                    <h2 className="section-title">What Clients Say</h2>
                                </div>
                            </div>
                            {/* /End col */}
                        </div>
                        {/* /End row */}
                        <div className="row">
                            <div className="col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-8">
                                <div className="owl-carousel feedback-slider">
                                    <div className="feedback-slider-item">
                                        <img
                                            src={data?.[activeIndex]?.cust_image || DefaultUser}
                                            width={"100px"}
                                            className="center-block img-circle"
                                            alt="Customer Feedback"
                                        />
                                        <h3 className="customer-name">{data?.[activeIndex]?.cust_name}</h3>
                                        <p>
                                            {data?.[activeIndex]?.feedback}
                                        </p>
                                        <span className="light-bg customer-rating" data-rating={5} onClick={() => data?.length - 1 !== activeIndex && setActiveIndex(activeIndex + 1)}>
                                            {data?.[activeIndex]?.rating}
                                            <i className="fa fa-star" />
                                        </span>
                                    </div>
                                </div>
                                {/* /End feedback-slider */}
                                {/* side thumbnail */}
                                <div className="feedback-slider-thumb hidden-xs">
                                    <div className="thumb-prev" onClick={() => activeIndex > 0 && setActiveIndex(activeIndex - 1)}>
                                        <span>
                                            <img
                                                src={data?.[activeIndex - 1]?.cust_image || DefaultUser}
                                                alt="Customer Feedback"
                                            />
                                        </span>
                                        <span className="light-bg customer-rating">
                                            {data?.[activeIndex - 1]?.rating}
                                            <i className="fa fa-star" />
                                        </span>
                                    </div>
                                    <div className="thumb-next" onClick={() => data?.length - 1 !== activeIndex && setActiveIndex(activeIndex + 1)}>
                                        <span>
                                            <img
                                                src={data?.[activeIndex + 1]?.cust_image || DefaultUser}
                                                alt="Customer Feedback"
                                            />
                                        </span>
                                        <span className="light-bg customer-rating">
                                            {data?.[activeIndex + 1]?.rating}
                                            <i className="fa fa-star" />
                                        </span>
                                    </div>
                                </div>
                                {/* /side thumbnail */}
                            </div>
                            {/* /End col */}
                        </div>
                        {/* /End row */}
                    </div>
                    {/* /End container */}
                </div>
                {/* /End customer-feedback */}
            </section >
        </>
    )
}

export default Testimonials