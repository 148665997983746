import axiosInstance from "../axiosInstance";
import Swal from "sweetalert2";

export const sendEmail = (data, alert) => {
    axiosInstance("send-email/", {
        baseURL: "https://api.newgreensinn.com/",
        method: "POST",
        data: data
    }).then(res => {
        alert &&
            Swal.fire({
                title: "Thank you for your interest!",
                text: "Our agent will contact you soon!",
                icon: "success"
            });
    }).catch(err => { })
}