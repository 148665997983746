import { useState } from "react"
import Base from "../layout/LandingPage"
import axiosInstance from "../axiosInstance"
import Swal from "sweetalert2"
import { sendEmail } from "../helper/email"

const Contact = () => {

    const [input, setInput] = useState({
        name: "",
        phone: "",
        description: ""
    })
    const [errors, setErrors] = useState({
        name: "",
        phone: "",
        description: ""
    })

    const handleChange = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value })
    }

    const handleSubmit = () => {
        if (validate()) {
            sendEmail({
                subject: "Enquiry for New Greens Inn",
                message: `${input?.name || "Guest"} has contacted New Greens Inn, and the guest phone number is ${input?.phone}.\n ${input?.description}`
            }, false)
            axiosInstance("contact/", { method: "POST", data: input })
                .then(res => {
                    Swal.fire({
                        title: "Thank you for Reaching out!",
                        text: "Our agent will contact you soon!",
                        icon: "success"
                    });
                    setInput({
                        name: "",
                        phone: "",
                        description: ""
                    })
                }).catch(err => console.log(err))
        }
    }
    const validate = (fieldValues = input) => {
        let temp = { ...errors };

        if ("name" in fieldValues) {
            temp.name = fieldValues.name?.trim() === "" ? "Please Enter Name" : "";
        }
        if ("phone" in fieldValues) {
            temp.phone = fieldValues.phone === "" ? "Please Enter Phone" :
                !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(input?.phone) ? "Please Enter Valid number"
                    : "";
        }
        if ("description" in fieldValues) {
            temp.description = fieldValues.description?.trim() === "" ? "Please Enter Message" : "";
        }
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };

    return (
        <Base>
            <div className="container">
                <h1 className="title">Contact</h1>
                {/* form */}
                <div className="contact">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3893.972446779271!2d78.64134907483441!3d12.584067587696808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3badab853f97b0ed%3A0x89b9a04df25bfa5c!2sTHE%20GREENS%20INN!5e0!3m2!1sen!2sin!4v1712415032311!5m2!1sen!2sin"
                                    width="100%"
                                    height={300}
                                    frameBorder={0}
                                />
                            </div>
                            <div className="col-sm-6 col-sm-offset-3">
                                <div className="spacer">
                                    <h4>Write to us</h4>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            placeholder="Name"
                                            onChange={handleChange}
                                            name="name"
                                            value={input?.name}
                                        />
                                        <span className="text-danger">{errors?.name}</span>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="phone"
                                            placeholder="Phone"
                                            onChange={handleChange}
                                            name="phone"
                                            value={input?.phone}
                                        />
                                        <span className="text-danger">{errors?.phone}</span>
                                    </div>
                                    <div className="form-group">
                                        <textarea
                                            type="email"
                                            className="form-control"
                                            placeholder="Message"
                                            rows={4}
                                            defaultValue={""}
                                            onChange={handleChange}
                                            name="description"
                                            value={input?.description}
                                        />
                                        <span className="text-danger">{errors?.description}</span>
                                    </div>
                                    <button onClick={handleSubmit} className="btn btn-default">
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* form */}
            </div>

        </Base>
    )
}

export default Contact