import { useState } from "react"
import axiosInstance from "../axiosInstance"
import Swal from "sweetalert2"
import { sendEmail } from "../helper/email"
import Card from "../static/photos/card.jpg"
import whatsapp from "../static/whatsapp.png"

const Reservation = () => {

    const initial = {
        customer_name: "",
        customer_email: "",
        customer_phone: "",
        no_of_adults: "",
        no_of_children: "",
        check_in_date: "",
        check_out_date: "",
    }

    const [submit, setSubmit] = useState(false)
    const [input, setInput] = useState(initial)


    const handleChange = (e) => {
        if (e?.target?.name === "customer_phone" && e?.target?.value?.length === 10) {
            handleEmail()
        }
        setInput({ ...input, [e?.target?.name]: e?.target?.value })
    }

    const handleSubmit = () => {
        setSubmit(true);
        if (input?.customer_name !== "" && input?.customer_phone !== "" && input?.no_of_adults !== "" && /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(input?.customer_phone)) {
            setSubmit(false);
            axiosInstance("enquiry/", {
                method: "POST", data: {
                    customer_name: input?.customer_name,
                    customer_email: input?.customer_email !== "" ? input?.customer_email : null,
                    customer_phone: input?.customer_phone,
                    no_of_adults: input?.no_of_adults !== "" ? +input?.no_of_adults : null,
                    no_of_children: input?.no_of_children !== "" ? +input?.no_of_children : null,
                    check_in_date: input?.check_in_date !== "" ? input?.check_in_date : null,
                    check_out_date: input?.check_out_date !== "" ? input?.check_out_date : null,
                }
            }).then(res => {
                Swal.fire({
                    title: "Thank you for your Enquiry!",
                    text: "Our Agent will contact you soon.!",
                    icon: "success"
                });
                setInput(initial)
            }).catch(err => {
                console.log('err: ', err);
            })
        }
    }

    const handleEmail = () => {
        sendEmail({
            subject: "Room Enquiry for New Greens Inn",
            message: `${input?.customer_name || "Guest"} has requested information about the rooms, and the guest phone number is ${input?.customer_phone}. To complete the booking, kindly get in touch with the guest. `
        }, false)
    }

    return (
        <>
            {/* reservation-information */}
            <div id="information" className="spacer reserve-info ">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7 col-md-8">
                            <div className="wowload">
                                <h1 style={{ fontSize: "3rem", marginTop: "0px" }}>Contact Details</h1>
                                <h3 style={{ fontSize: "3rem", marginTop: "1em" }}>Mr. Marimuthu</h3>
                                <h3 style={{ marginTop: "-15px" }}>General Manager</h3>
                                <h3 style={{ fontSize: "3rem", marginTop: "1em" }}>
                                    <img width={"27px"} src={whatsapp} alt="whatsapp" />
                                    &nbsp;  9176598329</h3>
                                <h3 style={{ fontSize: "3rem", marginTop: "1em" }}>
                                    <i
                                        className="fa fa-phone"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        data-original-title="whatsapp"
                                    /> &nbsp;7200183835</h3>
                                <img style={{ width: "33rem" }} src={Card} alt="New Greens Inn" />
                            </div>
                        </div>
                        <div className="col-sm-5 col-md-4">
                            <h3>Reservation Enquiry</h3>
                            <div>
                                <div className="form-group">
                                    <input
                                        onChange={handleChange}
                                        value={input?.customer_name}
                                        type="text"
                                        className="form-control"
                                        placeholder="Name *"
                                        name="customer_name"
                                    />
                                    {(submit && input?.customer_name === "") &&
                                        < span className="text-danger">Please Enter Name</span>
                                    }
                                </div>
                                <div className="form-group">
                                    <input
                                        onChange={handleChange}
                                        value={input?.customer_email}
                                        name="customer_email"
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        onChange={handleChange}
                                        value={input?.customer_phone}
                                        name="customer_phone"
                                        type="number"
                                        className="form-control"
                                        required
                                        placeholder="Phone *"
                                    />
                                    {(submit && input?.customer_phone === "") ?
                                        <span className="text-danger">Please Enter Phone number</span>
                                        : (submit && !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(input?.customer_phone)) &&
                                        <span className="text-danger">Please Enter valid number</span>
                                    }
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <input
                                                required
                                                onChange={handleChange}
                                                value={input?.no_of_adults}
                                                name="no_of_adults"
                                                type="number"
                                                className="form-control"
                                                placeholder="No of Adults *"
                                            />
                                            {(submit && input?.no_of_adults === "") &&
                                                <span className="text-danger">Please Enter count</span>
                                            }
                                        </div>
                                        <div className="col-xs-6">
                                            <input
                                                onChange={handleChange}
                                                value={input?.no_of_children}
                                                type="number"
                                                name="no_of_children"
                                                className="form-control"
                                                placeholder="No of Children"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Check In Date</label>
                                    <input
                                        type="date"
                                        onChange={handleChange}
                                        value={input?.check_in_date}
                                        name="check_in_date"
                                        min={new Date().toISOString().split('T')[0]}
                                        className="form-control"
                                        placeholder="Check In Date"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Check Out Date</label>
                                    <input
                                        onChange={handleChange}
                                        value={input?.check_out_date}
                                        type="date"
                                        name="check_out_date"
                                        min={new Date().toISOString().split('T')[0]}
                                        className="form-control"
                                        placeholder="Check out Date"
                                    />
                                </div>
                                <button className="btn btn-default" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/* reservation-information */}
        </>
    )
}

export default Reservation