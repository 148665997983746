import { useEffect, useState } from "react"
import Base from "../layout/LandingPage"
import axiosInstance from "../axiosInstance"
import { Link } from "react-router-dom"

const Gallery = () => {

    const [data, setData] = useState("")

    useEffect(() => {
        getData(1)
        //eslint-disable-next-line
    }, [])

    const getData = (activePage) => {
        axiosInstance(`gallery/?page=${activePage}`, { method: "GET" })
            .then(res => {
                setData({ ...res?.data, page: Math.round(res?.data?.count / 12) === 0 ? 1 : Math.round(res?.data?.count / 12) })
            }).catch(err => {
                getData(1)
            })
    }

    return (
        <Base>
            <div className="container">
                <h1 className="title">Gallery</h1>
                <div className="row gallery">
                    {
                        data?.results?.map(res => {
                            return (
                                <div className="col-12 col-md-4 wowload fadeInUp" key={res?.id}>
                                    <a
                                        href="#"
                                        className="gallery-image"
                                        data-gallery=""
                                    >
                                        <img
                                            src={res?.image}
                                            style={{ maxHeight: "24rem", minWidth: "32rem", maxWidth: "32rem", minHeight: "24rem" }}
                                            className="img-responsive"
                                        />
                                    </a>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="text-center">
                    <ul className="pagination">
                        <li onClick={() => getData(1)}>
                            <Link href="#">«</Link>
                        </li>
                        {
                            new Array(data?.page)?.fill("")?.map((res, id) => {
                                return (
                                    <li key={id} onClick={() => getData(id + 1)}>
                                        <Link to="#">{id + 1}</Link>
                                    </li>
                                )
                            })
                        }
                        <li onClick={() => getData(data?.page)}>
                            <Link href="#">»</Link>
                        </li>
                    </ul>
                </div>
            </div>

        </Base>
    )
}

export default Gallery