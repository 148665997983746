

const Footer = () => {
    return (
        <>
            <>
                <footer className="spacer">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-5">
                                <h4>New Greens Inn</h4>
                                <p>
                                    New Greens Inn was these three and songs arose whose. Of in vicinity
                                    contempt together in possible branched. Assured company hastily
                                    looking garrets in oh. Most have love my gone to this so. Discovered
                                    interested prosperous the our affronting insipidity day. Missed
                                    lovers way one vanity wishes nay but. Use shy seemed within twenty
                                    wished old few regret passed. Absolute one hastened mrs any
                                    sensible.{" "}
                                </p>
                            </div>
                            <div className="col-sm-3">
                            </div>
                            <div className="col-sm-4 subscribe">
                                <h4>Send a message</h4>
                                <div className="form-group">
                                    <textarea
                                        className="form-control"
                                        placeholder="Message"
                                        rows={4}
                                        defaultValue={""}
                                    />
                                </div>
                                <span className="input-group-btn">
                                    <button className="btn btn-default" type="button">
                                        Send
                                    </button>
                                </span>
                                <div className="social">
                                    <a href="#">
                                        <i
                                            className="fa fa-facebook-square"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            data-original-title="facebook"
                                        />
                                    </a>
                                    <a href="#">
                                        <i
                                            className="fa fa-instagram"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            data-original-title="instragram"
                                        />
                                    </a>
                                    <a href="#">
                                        <i
                                            className="fa fa-twitter-square"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            data-original-title="twitter"
                                        />
                                    </a>
                                    <a href="#">
                                        <i
                                            className="fa fa-pinterest-square"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            data-original-title="pinterest"
                                        />
                                    </a>
                                    <a href="#">
                                        <i
                                            className="fa fa-tumblr-square"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            data-original-title="tumblr"
                                        />
                                    </a>
                                    <a href="#">
                                        <i
                                            className="fa fa-youtube-square"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            data-original-title="youtube"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/*/.row*/}
                    </div>
                </footer>
            </>

        </>
    )
}

export default Footer