import Base from "../layout/LandingPage"


const About = () => {
    return (
        <Base>
            <div className="container">
                <h2>About &amp; Yealgiri hills</h2>
                <p>
                    Yelagiri Hills is one of the hill stations of Tamil Nadu. Yelagiri hill top can be reached by a winding ghat road, that has 14 hairpin bends.
                    The seventh hairpin bend is significant, since it offers the view of the slop of the mountain and the green forest covering as a carpet of the hill.
                    Punganur Lake which is an artificial lake made in an are of 56.706 square metres. Boating on the waters of this lake can be an
                    enchanting experience. Other attractions are a children’s park.Near the Punganoor Lake, a Herbal Farm, is maintained by the forest
                    Department with rare herbals used in the Siddha And Ayurvedic medical treatments.A Good Park is being formed at KODAI VIZHA THIDAL
                    to entertain Tourists.
                    <br />
                    <br />
                    Yelagiri Hills in northern Tamil Nadu, India, has a history dating back more than 200 years to when the Malaiyali
                    tribe built clay huts on the hill's flat peak. The huts provided shelter, storage, farming, and cattle, and today
                    only one old hut remains. The hut is a symbol of the tribe's transition from foraging to a more modern lifestyle.
                </p>
                <h2>Reach Yelagiri</h2>
                <p>
                    In order to reach Yelagiri by road, the tourists can either take a regular bus service or drive down with their
                    own vehicle. The town is well linked with fine road from Ponneri. KSRTC bus service is available regularly from
                    Chennai, Bangalore, Tirpathur, Krishnagiri and Vanayambadi to reach Yelagiri.
                </p>
            </div>
        </Base>
    )
}

export default About