import { useEffect, useState } from "react"
import Base from "../layout/LandingPage"
import axiosInstance from "../axiosInstance"

const Amenities = () => {

    const [data, setData] = useState("")

    useEffect(() => {
        getData()
        //eslint-disable-next-line
    }, [])

    const getData = () => {
        axiosInstance(`amenity/`, { method: "GET" })
            .then(res => {
                setData(res?.data)
            }).catch(err => {
                getData()
                console.log('err: ', err);
            })
    }
    return (
        <>
            <Base>
                <div className="container">
                    <h1 className="title">Amenities</h1>
                    <div>
                        <ol>
                            {
                                data?.results?.map(res => {
                                    return (
                                        <li style={{ padding: "10px" }}>
                                            <h3>{res?.name}</h3>
                                            <h4>{res?.description}</h4>
                                        </li>
                                    )
                                })
                            }
                        </ol>
                    </div>
                </div>
            </Base>
        </>
    )
}

export default Amenities