import { useEffect, useState } from "react"
import Base from "../layout/LandingPage"
import axiosInstance from "../axiosInstance"
import { Link } from "react-router-dom"

const RoomsTariff = () => {

    const [data, setData] = useState("")

    useEffect(() => {
        getData(1)
        //eslint-disable-next-line
    }, [])

    const getData = (number) => {
        axiosInstance(`rooms/?page=${number}`, { method: "GET" })
            .then(res => {
                setData({ ...res?.data, page: Math.round(res?.data?.count / 12) === 0 ? 1 : Math.round(res?.data?.count / 12) })
            }).catch(err => {
                getData(1)
                console.log('err: ', err);
            })
    }

    return (
        <Base>
            <div className="container">
                <h2>Rooms &amp; Tariff</h2>
                {/* form */}
                <div className="row">
                    {
                        data?.results?.map(res => {
                            console.log('res: ', res);
                            return (
                                <div className="col-12 col-sm-6 wowload fadeInUp" key={res?.id}>
                                    <div className="rooms">
                                        <img
                                            src={res?.image}
                                            style={{ width: "100%", maxWidth: "55.5rem", minHeight: "41.7rem", maxHeight: "41.7rem" }}
                                            className="img-responsive"
                                        />
                                        <div className="info">
                                            <h3>{res?.room_type}</h3>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="text-center">
                    <ul className="pagination">
                        <li onClick={() => getData(1)}>
                            <Link to="#">«</Link>
                        </li>
                        {
                            new Array(data?.page)?.fill("")?.map((res, id) => {
                                return (
                                    <li key={id} onClick={() => getData(id + 1)}>
                                        <Link to="#">{id + 1}</Link>
                                    </li>
                                )
                            })
                        }
                        <li onClick={() => getData(data?.page)}>
                            <Link href="#">»</Link>
                        </li>
                    </ul>
                </div>
            </div>

        </Base>
    )
}

export default RoomsTariff