import Base from "../layout/LandingPage"
import banner1 from "../static/photos/banner1.jpg"
import Reservation from "./Reservation"
import Testimonials from "./Testimonials"

const Home = () => {
    return (
        <Base>
            <>
                {/* banner */}
                <div className="banner">
                    <img
                        src={banner1}
                        className="img-responsive"
                        alt="slide"
                    />
                    <div className="welcome-message">
                        <div className="wrap-info">
                            <div className="information">
                            </div>
                            <a
                                href="#"
                                className="arrow-nav scroll wowload fadeInDownBig"
                            >
                                <i className="fa fa-angle-down" />
                            </a>
                        </div>
                    </div>
                </div>
                {/* banner*/}
                <Reservation />
                <Testimonials />
            </>
        </Base>
    )
}

export default Home