import { Link } from "react-router-dom"
import logo from "../static/logo.png"

const Header = () => {
    return (
        <>
            <nav className="navbar navbar-default" role="navigation">
                <div className="container">
                    {/* Brand and toggle get grouped for better mobile display */}
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-1"
                        >
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </button>
                        <Link className="navbar-brand" to={"/"}>
                            <img src={logo} alt="holiday crown" width={"120%"} height={"120%"} />
                        </Link>
                    </div>
                    {/* Collect the nav links, forms, and other content for toggling */}
                    <div
                        className="collapse navbar-collapse navbar-right"
                        id="bs-example-navbar-collapse-1"
                    >
                        <ul className="nav navbar-nav">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/about">Introduction</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact</Link>
                            </li>
                            <li>
                                <Link to="/gallery">Gallery</Link>
                            </li>
                            <li>
                                <Link to="/amenities">Amenities</Link>
                            </li>
                            <li>
                                <Link to="/roomstariff">Rooms &amp; Tariff</Link>
                            </li>
                            <li>
                                <Link to="/activities">Activities</Link>
                            </li>
                        </ul>
                    </div>
                    {/* Wnavbar-collapse */}
                </div>
                {/* container-fluid */}
            </nav>

        </>
    )
}

export default Header